import { useEnglishStudentGroups } from 'queries/management';
import { useAllRecentHomeworks } from 'queries/tasks';
import { getStudentGroupId } from 'utils/groups';
import { useTrainingTab } from 'views/teacher/new-year-setup-view/tabs/training-tab/hooks';
import { TRAINING_MODULE_ESSENTIAL } from 'views/teacher/teacher-training-view/module-specs';

export const useGetDepartmentDashStats = () => {
  const trainingTab = useTrainingTab();
  const { data: classes, isLoading: classesLoading } = useEnglishStudentGroups();
  const { data: homeworks, isLoading: homeworksLoading } = useAllRecentHomeworks();
  const numClassesTotal = classes?.length ?? 0;
  const classesWithRecentHomework =
    classes
      ?.filter(c => homeworks?.find(h => h.studentGroupId === getStudentGroupId(c)))
      .map(c => c.name) || [];
  const numStaffTotal =
    trainingTab.trainingSummaries === undefined
      ? 0
      : Object.keys(trainingTab.trainingSummaries).length;
  const numStaffCompletedEssentialTraining = Object.entries(
    trainingTab.trainingSummaries ?? {},
  ).filter(([, summary]) =>
    summary?.modules.find(
      module => module.complete && module.spec.name === TRAINING_MODULE_ESSENTIAL,
    ),
  ).length;

  // Does not yet exist
  // const classesWithHandInPageViewed = useGetHandInViewStatusForHomeworks(classesWithRecentHomework);

  return {
    loading: classesLoading || homeworksLoading,
    numClassesTotal,
    classesWithRecentHomework,
    numStaffTotal,
    numStaffCompletedEssentialTraining,
  };
};
