import { AdminContainer } from 'app/containers/admin-container/admin-container';
import { UserRole } from 'queries/session';
import { Outlet, RouteObject } from 'react-router-dom';
import { BookView } from 'views/admin/views/book-view/book-view';

import { RequiresRole } from './requires-role';

export const adminRoutes: RouteObject = {
  path: '/admin/super/*',
  element: (
    <RequiresRole role={UserRole.Admin}>
      <AdminContainer showSuperAdminSidebarLinks>
        <Outlet />
      </AdminContainer>
    </RequiresRole>
  ),
  children: [
    {
      path: 'goldreader',
      lazy: () => import('views/admin/views/superadmin/goldreader-view'),
    },
    {
      path: 'goldreaderadmin',
      lazy: () => import('views/admin/views/superadmin/goldreader-warn-view'),
    },
    {
      path: 'student',
      lazy: () => import('views/admin/views/superadmin/student-book-admin-view'),
    },
    {
      path: 'allbooks',
      lazy: async () => {
        const component = await import('views/admin/views/book-manager-view/book-manager-view');

        return { element: <component.BookManagerView showAllBooks /> };
      },
    },
    {
      path: 'allbooks/:bookId',
      element: <BookView allBooks />,
      children: [
        {
          path: '',
          lazy: async () => {
            const component = await import('views/admin/views/book-overview-view');

            return { element: <component.BookOverviewView /> };
          },
        },
        {
          path: 'edit',
          lazy: async () => {
            const component = await import('views/admin/views/book-editor-view');

            return { element: <component.BookEditorView /> };
          },
        },
        {
          path: 'questions',
          lazy: () => import('views/admin/views/ebooks/silver-reader-questions-editor'),
        },
      ],
    },
  ],
};
