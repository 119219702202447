export enum View {
  Landing = 'landing-view',
  Task = 'task-view',
  Library = 'library-view',
  Explore = 'explore-view',
  ChooseNewBook = 'choose-new-book-view',
  AddGoldReaderBook = 'add-gold-reader-book-view',
  League = 'league-view',
  EQM = 'eqm-view',
  EQMCustomBooks = 'eqm-custom-books-view',
  EQMCustomBookQuestions = 'eqm-custom-books-questions-view',
  Teacher = 'teacher-xp-view',
  HandIn = 'hand-in-view',
  VocabLanding = 'vocab-landing-view',
  Vocab = 'vocab-view',
  GoldReader = 'gold-reader-view',
  Onboarding = 'onboarding-view',
  TeacherCPD = 'teacher-cpd-view',
  TeacherReports = 'teacher-reports-view',
  WhatsNew = 'whats-new-view',
  TeacherLibrary = 'teacher-library-view',
  StaffManagerList = 'staff-manager-list',
  StaffKeyContacts = 'staff-key-contacts',
  TeacherTraining = 'teacher-training-view',
  MISSyncImport = 'mis-sync-import',
  NewYearSetup = 'new-year-setup',
  NewYearSetupImportClasses = 'new-year-setup/import-classes-and-students',
  SchoolSubscription = 'school-subscription',
  TeacherLeaderboard = 'teacher-leaderboard',
  Feedback = 'feedback-view',
  GettingStarted = 'getting-started',
  Dashboard = 'dashboard-view',
  DepartmentDashboard = 'department-dashboard-view',
}
