import { ModuleSpec } from '@sparx/teacher-training';

import step01Img from './img/video-01-intro-to-reader.png';
import step02Img from './img/video-02-library.png';
import step03Img from './img/video-03-checking-student-are-reading.png';
import step04Img from './img/video-04-example-of-content.png';
import step05Img from './img/video-05-choosing-books.png';
import step06Img from './img/video-06-how-students-read.png';
import step08Img from './img/video-08-getting-students-started.png';
import step09Img from './img/video-09-gold-reader.png';
import step10Img from './img/video-10-reading-test.png';
import step11Img from './img/video-11-creating-homework.png';
import step12Img from './img/video-12-editing-homework.png';
import step13Img from './img/video-13-hand-in.png';
import step14Img from './img/video-14-report.png';
import step15Img from './img/video-15-support.png';

export const TRAINING_MODULE_ESSENTIAL = 'READER_TRAINING_MODULE_ESSENTIAL_LEVEL';
export const moduleEssential: ModuleSpec = {
  name: TRAINING_MODULE_ESSENTIAL,
  title: 'Essential Training',
  steps: [
    {
      kind: 'video',
      id: 'what-is-sparx-reader',
      title: 'What is Sparx Reader?',
      timeToComplete: 2,
      video: '1_1_what_is_reader.mp4',
      videoCover: 'cover.png',
      videoSubs: '1_1_what_is_reader.vtt',
      upNextImage: step01Img,
    },
    {
      kind: 'video',
      id: 'the-sparx-reader-library',
      title: 'The Sparx Reader library',
      timeToComplete: 2,
      video: '2_1_the_reader_library.mp4',
      videoCover: 'cover.png',
      videoSubs: '2_1_the_reader_library.vtt',
      upNextImage: step02Img,
    },
    {
      kind: 'video',
      id: 'check-students-reading-carefully',
      title: 'Checking students are reading',
      timeToComplete: 2,
      video: '2_2_checking_students_are_reading.mp4',
      videoCover: 'cover.png',
      videoSubs: '2_2_checking_students_are_reading.vtt',
      upNextImage: step03Img,
    },
    {
      kind: 'video',
      id: 'an-example-of-our-questions',
      title: 'An example of our questions',
      timeToComplete: 2,
      video: '2_3_example_questions.mp4',
      videoCover: 'cover.png',
      videoSubs: '2_3_example_questions.vtt',
      upNextImage: step04Img,
    },
    {
      kind: 'video',
      id: 'choosing-books',
      title: 'Choosing books for students',
      timeToComplete: 2,
      video: '2_4_choosing_books.mp4',
      videoCover: 'cover.png',
      videoSubs: '2_4_choosing_books.vtt',
      upNextImage: step05Img,
    },
    {
      kind: 'video',
      id: 'how-students-read',
      title: 'How students read on Sparx Reader',
      timeToComplete: 3,
      video: '3_1_how_students_read.mp4',
      videoCover: 'cover.png',
      videoSubs: '3_1_how_students_read.vtt',
      upNextImage: step06Img,
    },
  ],
};

export const TRAINING_MODULE_USING_SPARX_READER = 'READER_TRAINING_MODULE_USING_SPARX_READER';
export const READ_TRAINING_BOOK_STEP_ID = 'READ_TRAINING_BOOK_STEP_ID';
export const TRAINING_BOOK_ID = 'c9244f0b-a726-4b9b-9e90-92b4bdce6529';
export const moduleUsingSparxReader: ModuleSpec = {
  name: TRAINING_MODULE_USING_SPARX_READER,
  title: 'Using Sparx Reader',
  steps: [
    {
      kind: 'link',
      id: READ_TRAINING_BOOK_STEP_ID,
      title: 'Complete the student training book',
      description: 'Read the training book',
      to: '/onboarding?src=training',
    },
    {
      kind: 'video',
      id: 'getting-students-started',
      title: 'Getting students started with Sparx Reader',
      timeToComplete: 2,
      video: '3_2_getting_students_started.mp4',
      videoCover: 'cover.png',
      videoSubs: '3_2_getting_students_started.vtt',
      upNextImage: step08Img,
    },
    {
      kind: 'video',
      id: 'gold-reader',
      title: 'Gold Reader',
      timeToComplete: 3,
      video: '3_3_gold_reader.mp4',
      videoCover: 'cover.png',
      videoSubs: '3_3_gold_reader.vtt',
      upNextImage: step09Img,
    },
    {
      kind: 'video',
      id: 'setting-the-sparx-reading-test',
      title: 'Setting the Sparx Reading Test',
      timeToComplete: 2,
      video: '4_1_setting_the_srt.mp4',
      videoCover: 'cover.png',
      videoSubs: '4_1_setting_the_srt.vtt',
      upNextImage: step10Img,
    },
    {
      kind: 'video',
      id: 'creating-a-homework-plan',
      title: 'Creating a homework plan',
      timeToComplete: 2,
      video: '5_1_creating_a_homework_plan.mp4',
      videoCover: 'cover.png',
      videoSubs: '5_1_creating_a_homework_plan.vtt',
      upNextImage: step11Img,
    },
    {
      kind: 'video',
      id: 'editing-homework',
      title: 'Editing homework',
      timeToComplete: 1,
      video: '5_2_editing_homework.mp4',
      videoCover: 'cover.png',
      videoSubs: '5_2_editing_homework.vtt',
      upNextImage: step12Img,
    },
    {
      kind: 'video',
      id: 'the-hand-in-page',
      title: 'The Hand-in page',
      timeToComplete: 2,
      video: '6_1_the_hand-in_page.mp4',
      videoCover: 'cover.png',
      videoSubs: '6_1_the_hand-in_page.vtt',
      upNextImage: step13Img,
    },
    {
      kind: 'video',
      id: 'the-student-activity-report',
      title: 'The Student Activity Report',
      timeToComplete: 1,
      video: '6_2_the_student_activity_report.mp4',
      videoCover: 'cover.png',
      videoSubs: '6_2_the_student_activity_report.vtt',
      upNextImage: step14Img,
    },
    {
      kind: 'video',
      id: 'getting-help-and-support',
      title: 'Getting help and support',
      timeToComplete: 1,
      video: '7_1_getting_help_and_support.mp4',
      videoCover: 'cover.png',
      videoSubs: '7_1_getting_help_and_support.vtt',
      upNextImage: step15Img,
    },
  ],
};

export const TRAINING_MODULE_TRINITY_LAUNCHING_READER =
  'READER_TRAINING_MODULE_TRINITY_LAUNCHING_READER';
export const moduleTrinityLaunchingReader: ModuleSpec = {
  name: TRAINING_MODULE_TRINITY_LAUNCHING_READER,
  title: 'Trinity case study: Launching Sparx Reader',
  isOptional: true,
  steps: [
    {
      kind: 'video',
      id: 'the-most-important-thing',
      title: 'The Most Important Thing to get right when implementing Sparx Reader',
      video:
        'maximising-impact/1-the-most-important-thing-to-get-right-when-implementing-sparx-reader.mp4',
      timeToComplete: 2,
    },
    {
      kind: 'video',
      id: 'intro-students-to-sparx-reader',
      title: 'Introducing students to Sparx Reader',
      video: 'maximising-impact/2-introducing-students-to-sparx-reader.mp4',
      timeToComplete: 2,
    },
    {
      kind: 'video',
      id: 'make-success-inevitable-in-september',
      title: 'Make success inevitable in September',
      video: 'maximising-impact/3-make-success-inevitable-in-september.mp4',
      timeToComplete: 3,
    },
    {
      kind: 'video',
      id: 'reminders-deadlines-and-sanctions',
      title: 'Reminders, deadlines and sanctions',
      video: 'maximising-impact/4-reminders-deadlines-and-sanctions.mp4',
      timeToComplete: 4,
    },
    {
      kind: 'video',
      id: 'securing-staff-buy-in',
      title: 'Securing staff buy-in',
      video: 'maximising-impact/5-securing-staff-buy-in.mp4',
      timeToComplete: 5,
    },
  ],
};

export const TRAINING_MODULE_TRINITY_SUCCESS_WITH_READER =
  'READER_TRAINING_MODULE_TRINITY_SUCCESS_WITH_READER';
export const moduleTrinitySuccessWithReader: ModuleSpec = {
  name: TRAINING_MODULE_TRINITY_SUCCESS_WITH_READER,
  title: 'Trinity case study: Getting success with Sparx Reader',
  isOptional: true,
  steps: [
    {
      kind: 'video',
      id: 'what-about-reading-for-pleasure',
      title: 'What about reading for pleasure?',
      video: 'maximising-impact/6-what-about-reading-for-pleasure.mp4',
      timeToComplete: 4,
    },
    {
      kind: 'video',
      id: 'daily-weekly-termly-tasks-as-a-reader-leader',
      title: 'Daily, weekly, termly tasks as a Reader Leader',
      video: 'maximising-impact/7-daily-weekly-termly-tasks-as-a-reader-leader.mp4',
      timeToComplete: 3,
    },
    {
      kind: 'video',
      id: 'accuracy-aiming-for-excellent',
      title: 'Accuracy - aiming for excellent',
      video: 'maximising-impact/8-accuracy-aiming-for-excellent.mp4',
      timeToComplete: 1,
    },
    {
      kind: 'video',
      id: 'struggling-students',
      title: 'Struggling students',
      video: 'maximising-impact/9-struggling-students.mp4',
      timeToComplete: 1,
    },
    {
      kind: 'video',
      id: 'device-access',
      title: 'Device access',
      video: 'maximising-impact/10-device-access.mp4',
      timeToComplete: 4,
    },
  ],
};

export const TRAINING_MODULES: ModuleSpec[] = [
  moduleEssential,
  moduleUsingSparxReader,
  moduleTrinityLaunchingReader,
  moduleTrinitySuccessWithReader,
];
