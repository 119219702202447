import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { schoolsClient } from 'api';
import { isAfter } from 'date-fns';
import { useSession, useUser } from 'queries/session';

export const useSchoolID = () => {
  const user = useUser();

  // Fallback to the value set on the window if user is undefined.
  return user?.schoolId || window.READER_SCHOOL_ID;
};

export const SchoolQueryKey = 'schoolstore/GetSchool';

const useSchoolBase = <T = School>(options?: UseQueryOptions<School, Error, T, [string]>) => {
  const schoolID = useSchoolID();
  return useQuery(
    [SchoolQueryKey],
    () => schoolsClient.getSchool({ name: 'schools/' + schoolID }).response,
    {
      cacheTime: 900000, // 15 minutes
      staleTime: 300000, // 5 minutes
      ...options,
      enabled:
        options?.enabled !== undefined
          ? options.enabled && schoolID !== undefined
          : schoolID !== undefined,
    },
  );
};

// useSchool loads the school from school store.
export const useSchool = (opts?: { suspense?: boolean; enabled?: boolean }) => useSchoolBase(opts);

// Recreated from teacher portal lib/api/reactQuery/schools.ts
const getSchoolCreateDate = (school?: School) => {
  if (!school || !school.createTime) {
    return undefined;
  }

  const schoolCreateTime = Timestamp.toDate(school.createTime);

  // Look for the most recent instance of the Reader product being added
  if (school.productChangeTimes.length) {
    const readerLastAdded = school.productChangeTimes.reduce((acc, curr) => {
      return curr.product === Product.SPARX_READER &&
        curr.enabledTime &&
        isAfter(Timestamp.toDate(curr.enabledTime), acc)
        ? Timestamp.toDate(curr.enabledTime)
        : acc;
    }, schoolCreateTime);

    if (isAfter(readerLastAdded, schoolCreateTime)) {
      return readerLastAdded;
    }
  }

  // Next, look for the school unarchive time
  if (school.undeleteTime) {
    const undeleteTime = Timestamp.toDate(school.undeleteTime);
    if (isAfter(undeleteTime, schoolCreateTime)) {
      return undeleteTime;
    }
  }

  // Just use the create time
  return schoolCreateTime;
};

/**
 * Gets the school creation date. This is the date the school was created _or_ the
 * date the school was undeleted if it was previously deleted.
 */
export const useSchoolCreateDate = () => useSchoolBase({ select: getSchoolCreateDate });

export const useAcademicYearStartDate = () => {
  const session = useSession();
  if (!session.data) {
    return session;
  }
  return {
    ...session,
    data: session.data.schoolYearStart && Timestamp.toDate(session.data.schoolYearStart),
  };
};
