import { ModuleStepState } from '@sparx/teacher-training';
import slugify from 'slugify';

import { trainingTabs } from './training-tabs';

export const slug = (tab: { title: string }) => slugify(tab.title, { lower: true });

export const makeTabPath = (tab: { title: string }) => {
  return `/teacher/training/${slug(tab)}`;
};

export const makeStepPath = (tab: { title: string }, step: { title: string }) => {
  return `/teacher/training/${slug(tab)}/${slug(step)}`;
};

export const makePathForStep = (step: ModuleStepState) => {
  const tab = trainingTabs.find(tab =>
    tab.modules.some(module => module.steps.includes(step.spec)),
  );
  if (!tab) return '';
  return makeStepPath(tab, { title: step.spec.title });
};
