import {
  faCircle,
  faCircleNotch,
  faExclamationTriangle,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ProgressBucket,
  StudentState,
} from '@sparx/api/apis/sparx/reading/monitoring/v1/monitoring';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import classNames from 'classnames';
import { AccuracyLabelWithTooltip } from 'components/accuracy/accuracy';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { Loading } from 'components/loading/loading';
import { SortableTable } from 'components/table/sortable-table';
import { TableCell, TableRow, TableRowFull } from 'components/table/table-row';
import { Title } from 'components/title/title';
import moment, { Moment } from 'moment';
import { useBookMetadata } from 'queries/books';
import {
  IStudentWithExperience,
  useAllStudentGroups,
  useTeacherStudentsWithExperience,
  useWatchStudentGroups,
} from 'queries/management';
import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getStudentGroupId } from 'utils/groups';
import { useQueryParamStringArray } from 'utils/hooks';
import { timestampToMoment } from 'utils/time';
import { GoldReaderChip } from 'views/teacher/components/gold-reader-chip';
import { TeacherContent } from 'views/teacher/components/teacher-content';
import styles from 'views/teacher/components/teacher-view.module.css';

import { ChooseGroupView } from '../choose-group-view/choose-group-view';

interface IStudentRow {
  student: IStudentWithExperience;
  counts?: ProgressBucket;
  state?: StudentState;
  lastActive?: number; //seconds
  online: boolean;
  bookId: string | undefined;
  lastReadingCheckPassed?: Moment;
  lastLogin?: Moment;
}

const tableSorts: Record<string, (a: IStudentRow, b: IStudentRow) => number> = {
  online: (a, b) => (b.online ? 1 : 0) - (a.online ? 1 : 0),
  name: (a, b) =>
    a.student.firstName.localeCompare(b.student.firstName) ||
    b.student.lastName.localeCompare(b.student.lastName) ||
    a.student.userId.localeCompare(b.student.userId),
  onlineFirst: (a, b) => (a.online && !b.online ? -1 : !a.online && b.online ? -1 : 0),

  status: (a, b) => tableSorts.online(a, b) || tableSorts.lastCheck(a, b),
  lastCheck: (a, b) =>
    (b.lastReadingCheckPassed || b.lastLogin ? 1 : 0) -
      (a.lastReadingCheckPassed || a.lastLogin ? 1 : 0) ||
    (a.lastReadingCheckPassed || a.lastLogin)?.diff(b.lastReadingCheckPassed || b.lastLogin) ||
    tableSorts.name(a, b),
  srp: (a, b) =>
    (b.counts?.srpAwarded || 0) - (a.counts?.srpAwarded || 0) || tableSorts.status(a, b),
  words: (a, b) =>
    (b.counts?.wordsRead || 0) - (a.counts?.wordsRead || 0) || tableSorts.status(a, b),
  accuracy: (a, b) =>
    (b.counts?.taskCount ? 1 : 0) - (a.counts?.taskCount ? 1 : 0) || //check
    (b.counts?.correctCount || 0) / (b.counts?.taskCount || 1) -
      (a.counts?.correctCount || 0) / (a.counts?.taskCount || 1) ||
    tableSorts.status(a, b),
};

const LiveView = () => {
  const location = useLocation();
  const { sendEvent } = useClientEvent();
  const query = queryString.parse(location?.search ? location.search : '');
  const groupIDs = useQueryParamStringArray(query.group);
  const { data: groups, isLoading: isLoadingGroups } = useAllStudentGroups();

  const currentGroups: Group[] =
    groups?.filter(a => groupIDs.find(g => g === getStudentGroupId(a))) || [];

  const { data, isLoading } = useWatchStudentGroups(groupIDs);
  const stateMap = useStudentStateMap(data?.states);

  // TODO: use react query to cache these?
  const intervals = [0, 15, 30, 45, 60, 75, 90, 105].map(offset =>
    nearestPastMinutes(15, moment().subtract(offset, 'minutes')),
  );
  const [interval, setInterval] = useState<Moment>(intervals[1]);

  const changeInterval = (value: string) => {
    const found = intervals.find(v => v.format('HH:mm') === value);
    if (found) {
      setInterval(found);
      sendEvent({
        category: 'live monitoring',
        action: 'change interval',
        labels: { interval: found.toISOString() },
      });
    }
  };

  const { data: students, isLoading: isLoadingStudents } = useTeacherStudentsWithExperience();
  if (isLoading || isLoadingStudents || isLoadingGroups) {
    return (
      <TeacherContent>
        <Loading />
      </TeacherContent>
    );
  }

  const sumBucketNewReturnsOld = (state?: StudentState): ProgressBucket | undefined => {
    if (!state) {
      return undefined;
    }
    let b: ProgressBucket | undefined;
    for (const bucket of state.buckets) {
      if ((timestampToMoment(bucket.startTime)?.diff(interval) || 0) > 0) {
        if (!b) {
          b = {
            ...bucket,
            startTime: bucket.startTime,
            endTime: bucket.endTime,
          };
        } else {
          //merge
          b.correctCount += bucket.correctCount;
          b.wordsRead += bucket.wordsRead;
          b.taskCount += bucket.taskCount;
          b.srpAwarded += bucket.srpAwarded;
        }
      }
    }
    return b;
  };

  const rows: IStudentRow[] = Object.values(students || {})
    .filter(s => groupIDs.find(g => g === s.studentGroupId))
    .map(student => {
      const state = stateMap ? stateMap[student.userId] : undefined;
      return {
        student,
        counts: sumBucketNewReturnsOld(state),
        bookId: state?.currentTask?.bookId,
        ...getStudentStateSummary(state),
      };
    });

  if (groupIDs.length === 0) {
    return <ChooseGroupView title="Live Monitoring" icon={faEye} />;
  }

  const table = (
    <SortableTable
      columns={[
        { name: 'Student', sort: 'name', width: 'minmax(180px, 17%)', sticky: true },
        { name: 'Status', sort: 'status', width: 'minmax(100px, 0.75fr)' },
        { name: 'Last check passed', sort: 'lastCheck', width: 'minmax(200px, 1fr)' },
        { name: 'SRP', sort: 'srp', width: 'minmax(80px, 0.5fr)' },
        { name: 'Words', sort: 'words', width: 'minmax(80px, 0.5fr)' },
        { name: 'Accuracy', sort: 'accuracy', width: 'minmax(100px, 0.8fr)' },
        { name: 'Current activity', width: 'minmax(300px, 1fr)' },
      ]}
      sorts={tableSorts}
      defaultSort="lastCheck"
      data={rows}
      noRows={<TableRowFull>There are no students in the selected classes.</TableRowFull>}
      analyticsCategory="live monitoring"
      analyticsLabels={{ table: 'live table', groupIds: groupIDs.join(',') }}
    >
      {row => (
        <TableRow
          key={row.student.userId}
          className={classNames({
            [styles.StudentRowFade]: !row.online,
          })}
        >
          <TableCell sticky={true}>
            {row.student.firstName} {row.student.lastName}
          </TableCell>
          <TableCell>
            <LiveStudentOnlineState row={row} />
          </TableCell>
          <TableCell>
            <LiveStudentLoginState row={row} />
          </TableCell>
          <TableCell>{row.counts?.srpAwarded || 0}</TableCell>
          <TableCell>{row.counts?.wordsRead || 0}</TableCell>
          <TableCell>
            {row.counts?.taskCount ? (
              <AccuracyLabelWithTooltip
                decimalAccuracy={row.counts.correctCount / (row.counts.taskCount || 1)}
              />
            ) : (
              '-'
            )}
          </TableCell>
          <TableCell className={styles.LiveBookCell}>
            {row.online && <CurrentState row={row} />}
          </TableCell>
        </TableRow>
      )}
    </SortableTable>
  );

  return (
    <TeacherContent>
      <Title>Live Monitoring</Title>

      <div className="space-x" style={{ float: 'right', marginBottom: 20 }}>
        <span>Session start time:</span>
        <select
          value={interval.format('HH:mm')}
          className="text-input"
          onChange={e => changeInterval(e.target.value)}
        >
          {intervals?.map((opt, i) => (
            <option key={i} value={opt.format('HH:mm')}>
              {opt.format('HH:mm')}
            </option>
          ))}
        </select>
      </div>

      <h2 style={{ marginTop: 0 }}>Live Monitoring</h2>
      <div className={styles.LiveGroups}>
        {currentGroups?.map(g => (
          <div className={styles.LiveGroup} key={g.name}>
            {g.displayName}
          </div>
        ))}
      </div>

      {table}
    </TeacherContent>
  );
};

const CurrentState = ({ row }: { row: IStudentRow }) => {
  const { data: book, isLoading } = useBookMetadata(row.bookId);
  if (row.state?.latestPath === '/task') {
    return (
      <>
        {book?.title ? (
          <>
            {row.state.currentTask?.state?.state.oneofKind === 'booklog' && <GoldReaderChip />}{' '}
            <i>Reading</i> <strong title={book?.title}>{book?.title}</strong>
          </>
        ) : row.state.currentTask?.state?.state.oneofKind === 'assessment' ? (
          'Reading test'
        ) : isLoading ? (
          <FontAwesomeIcon icon={faCircleNotch} spin={true} />
        ) : (
          <i>Unknown book</i>
        )}
      </>
    );
  }
  if (row.state?.latestPath === '/library/league') {
    return (
      <i>
        Browsing <strong>Leaderboard</strong>
      </i>
    );
  }
  if (row.state?.latestPath === '/library/vocab' || row.state?.latestPath === '/vocab') {
    return (
      <i>
        Browsing <strong>Vocab Check</strong>
      </i>
    );
  }
  if (row.state?.latestPath.startsWith('/library')) {
    return (
      <i>
        Browsing <strong>Library</strong>
      </i>
    );
  }
  return <></>;
};

const getLastActiveSeconds = (date: Moment | undefined) =>
  !date ? undefined : moment.duration(moment().diff(date)).asSeconds();

function nearestPastMinutes(interval: number, someMoment: Moment) {
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0).millisecond(0);
}

export const useStudentStateMap = (states: StudentState[] | undefined) =>
  useMemo(
    () =>
      states?.reduce<Record<string, StudentState>>((p, s) => {
        p[s.studentId] = s;
        return p;
      }, {}) || {},
    [states],
  );

export interface IStudentStateSummary {
  state?: StudentState;
  online: boolean;
  lastActive?: number;
  lastLogin?: moment.Moment;
  lastReadingCheckPassed?: moment.Moment;
}

export const getStudentStateSummary = (state: StudentState | undefined): IStudentStateSummary => {
  const lastActive = getLastActiveSeconds(timestampToMoment(state?.lastActive));
  return {
    state,
    lastActive,
    online: lastActive !== undefined && lastActive < 60,
    lastReadingCheckPassed: timestampToMoment(state?.lastReadingCheckPassed)?.isSame(
      moment(),
      'day',
    )
      ? timestampToMoment(state?.lastReadingCheckPassed)
      : undefined,
    lastLogin: timestampToMoment(state?.lastLogin)?.isSame(moment(), 'day')
      ? timestampToMoment(state?.lastLogin)
      : undefined,
  };
};

export const LiveStudentOnlineState = ({ row }: { row: IStudentStateSummary }) => (
  <>
    <FontAwesomeIcon
      icon={faCircle}
      className={styles.StudentRowOnline}
      style={{ color: row.online ? 'var(--spring-green-80)' : 'var(--carbon-grey-20)' }}
    />
    {row.online ? 'Online' : 'Offline'}
  </>
);

export const LiveStudentLoginState = ({
  row,
  showWarning,
}: {
  row: IStudentStateSummary;
  showWarning?: boolean;
}) => (
  <>
    {showWarning &&
      row.online &&
      (moment().diff(row.lastReadingCheckPassed, 'minutes') || 0) > 5 && (
        <FontAwesomeIcon icon={faExclamationTriangle} className={styles.StudentRowWarning} />
      )}
    {row.lastReadingCheckPassed?.fromNow() ||
      (row.lastLogin ? (
        <i className={styles.StudentRowDateFaded}>Logged in {row.lastLogin?.fromNow()}</i>
      ) : (
        <i className={styles.StudentRowDateFaded}>Not logged in today</i>
      ))}
  </>
);

export const Component = LiveView;
