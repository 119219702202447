import 'app/analytics';

import * as Tooltip from '@radix-ui/react-tooltip';
import { ThemeProvider } from '@sparx/design/context';
import { QueryClientProvider } from '@tanstack/react-query';
import { StatusNotification } from 'components/sections/status-notification';
import { queryClient } from 'queries/client';
import { CookiesProvider } from 'react-cookie';
import { RouterProvider } from 'react-router-dom';

import styles from './app.module.css';
import { DevTools } from './dev-tools/dev-tools';
import { routes } from './routes/routes';

const App = () => {
  return (
    <ThemeProvider themeStyles={styles}>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <Tooltip.Provider delayDuration={0}>
            <StatusNotification />
            <RouterProvider router={routes} />
          </Tooltip.Provider>

          <DevTools />
        </QueryClientProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

export default App;
