import * as Sentry from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleErr } from 'app/handle-err';

// React Query client with Sentry error capture
// https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      Sentry.withScope(scope => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash]);
        Sentry.captureException(error);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _vars, _ctx, mutation) => {
      Sentry.withScope(scope => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            mutation.options.mutationKey.filter(segment => typeof segment === 'string'),
          );
        }
        Sentry.captureException(err);
      });
    },
  }),
  defaultOptions: {
    queries: {
      onError: err => handleErr(err),
    },
    mutations: {
      onError: err => handleErr(err),
    },
  },
});

export const combineQueryStates = (...queryStates: { isLoading: boolean; isError: boolean }[]) => ({
  isLoading: queryStates.some(s => s.isLoading),
  isError: queryStates.some(s => s.isError),
});
