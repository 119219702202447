import { addDays, isBefore } from 'date-fns';
import { useMostRecentRolloverWasManual } from 'hooks/school-calendar';
import { useSession } from 'queries/session';
import { useAllHomeworks } from 'queries/tasks';
import { timestampToDate } from 'utils/time';

import { useGettingStartedOverviewTab } from './tabs/getting-started-overview-tab/hooks';
import { useImportTab } from './tabs/import-tab/hooks';
import { useGettingStartedMISTab, useMISTab } from './tabs/mis-tab/hooks';
import { useOverviewTab } from './tabs/overview-tab/hooks';
import { useResetTab } from './tabs/reset-tab/hooks';
import { useSettingsTab } from './tabs/settings-tab/hooks';
import { useStaffTab } from './tabs/staff-tab/hooks';
import { useTrainingTab } from './tabs/training-tab/hooks';
import { Tab } from './types';

// The number of days from the start of the school year after which the school is not
// considered getting started any more if they have set homework
const CUT_OFF_DAYS = 45;

export const useNewYearSetupTabs = (): Tab[] => {
  const overviewTab = useOverviewTab();
  const misTab = useMISTab();
  const contactsTab = useStaffTab();
  const trainingTab = useTrainingTab();
  const resetTab = useResetTab();
  const settingsTab = useSettingsTab();
  const importTab = useImportTab();

  return [overviewTab, misTab, contactsTab, trainingTab, resetTab, settingsTab, importTab];
};

export const useGettingStartedTabs = (): Tab[] => {
  const gettingStartedOverviewTab = useGettingStartedOverviewTab();
  const misTab = useGettingStartedMISTab();
  const contactsTab = useStaffTab();
  const trainingTab = useTrainingTab();
  const settingsTab = useSettingsTab();
  const importTab = useImportTab();

  return [gettingStartedOverviewTab, misTab, contactsTab, trainingTab, settingsTab, importTab];
};

/**
 * Returns whether the getting started information is loading, the total number of tabs and whether each tab is
 * completed
 */
export const useGettingStartedProgress = (): {
  isLoading: boolean;
  total: number;
  completed: number;
} => {
  let completed = 0;
  let total = 0;
  const tabs = useGettingStartedTabs();
  const isLoading = tabs.some(tab => tab.isLoading);
  if (isLoading) {
    return { isLoading, total: 0, completed: 0 };
  }
  for (const tab of tabs) {
    if (tab.id !== 'overview') {
      total++;
      if (tab.hasSuccess) {
        completed++;
      }
    }
  }
  return { isLoading, total, completed };
};

/**
 * Determine whether to show the "getting started" header
 * progress link. Note, it is always possible to get to the page even if this
 * returns false.
 *
 * Return whether loading date or if there was an error loading the data
 *
 * Don't show getting started if the school is in the interim state
 * Don't show if the last rollover was a manual one
 * Don't show if homework has been set and either all steps are complete or we
 *  are more than 45 days into the school year
 */
export const useGettingStartedHeaderLink = (): {
  isGettingStarted?: boolean;
  isLoading?: boolean;
  isError?: boolean;
} => {
  const inInterimStateOrLastRolloverManual = useMostRecentRolloverWasManual();
  const { data: session, isLoading: sessionLoading, isError: sessionError } = useSession();
  const { isLoading: gettingStartedLoading, total, completed } = useGettingStartedProgress();
  const {
    data: allHomeworks,
    isLoading: allHomeworksLoading,
    isError: allHomeworksError,
  } = useAllHomeworks();

  if (sessionError || allHomeworksError) {
    return { isError: true };
  }

  if (sessionLoading || gettingStartedLoading || allHomeworksLoading || !session) {
    return { isLoading: true };
  }

  const allGettingStartedStepsComplete = !gettingStartedLoading && total === completed;
  const schoolYearStartDate = timestampToDate(session.schoolYearStart);
  const now = new Date();
  const homeworkSet = allHomeworks?.length > 0;

  const nearStartOfSchoolYear = isBefore(now, addDays(schoolYearStartDate, CUT_OFF_DAYS));

  return {
    isGettingStarted: !(
      inInterimStateOrLastRolloverManual ||
      (homeworkSet && (allGettingStartedStepsComplete || !nearStartOfSchoolYear))
    ),
  };
};

/**
 * Determine whether to show the "getting started" home page widget.
 * Note, it is always possible to get to the page even if this returns false.
 *
 * Return whether loading date or if there was an error loading the data
 *
 * Don't show getting started if the school is in the interim state
 * Don't show if the last rollover was a manual one
 * Don't show if homework has been set and we are more than 45 days into the
 *   school year
 */
export const useGettingStartedHomepageBanner = (): {
  isGettingStarted?: boolean;
  isLoading?: boolean;
  isError?: boolean;
} => {
  const inInterimStateOrLastRolloverManual = useMostRecentRolloverWasManual();
  const { data: session, isLoading: sessionLoading, isError: sessionError } = useSession();
  const {
    data: allHomeworks,
    isLoading: allHomeworksLoading,
    isError: allHomeworksError,
  } = useAllHomeworks();

  if (sessionError || allHomeworksError) {
    return { isError: true };
  }

  if (sessionLoading || allHomeworksLoading || !session) {
    return { isLoading: true };
  }

  const schoolYearStartDate = timestampToDate(session.schoolYearStart);
  const now = new Date();
  const homeworkSet = allHomeworks?.length > 0;

  const nearStartOfSchoolYear = isBefore(now, addDays(schoolYearStartDate, CUT_OFF_DAYS));

  return {
    isGettingStarted: !(
      inInterimStateOrLastRolloverManual ||
      (homeworkSet && !nearStartOfSchoolYear)
    ),
  };
};
