import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/accuracy/accuracy.module.css';
import { Tooltip } from 'components/tooltip/Tooltip';
import { FC } from 'react';

export interface IAccuracyProps {
  decimalAccuracy: number;
}

export type AccuracyLevel = 'Excellent' | 'Good' | 'Ok' | 'Low' | 'Very low';
export const AccuracyTextStyles: Record<AccuracyLevel, string> = {
  Excellent: styles.ExcellentText,
  Good: styles.GoodText,
  Ok: styles.OkText,
  Low: styles.PoorText,
  'Very low': styles.BadText,
};

export const orderedAccuracies: AccuracyLevel[] = ['Excellent', 'Good', 'Ok', 'Low', 'Very low'];

export const getAccuracyLevel = (accuracy: number): AccuracyLevel => {
  if (accuracy >= 92) return 'Excellent';
  if (accuracy >= 84) return 'Good';
  if (accuracy >= 75) return 'Ok';
  if (accuracy >= 50) return 'Low';
  return 'Very low';
};

export const AccuracyLabelWithTooltip: FC<IAccuracyProps> = ({ decimalAccuracy }) => {
  const accuracy = Math.round(decimalAccuracy * 100);
  const accuracyLevel = getAccuracyLevel(accuracy);

  return (
    <div className={styles.FlexRow}>
      <Tooltip content={`${accuracy}%`}>
        <span className={classNames(styles.TooltipSpan, AccuracyTextStyles[accuracyLevel])}>
          {accuracyLevel}
          {accuracyLevel === 'Very low' && (
            <FontAwesomeIcon
              size={'sm'}
              className={classNames(styles.WarningTriangle)}
              icon={faExclamationTriangle}
            />
          )}
        </span>
      </Tooltip>
    </div>
  );
};
