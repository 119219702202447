import {
  GetMISStatusResponse,
  GetSchoolOnboardingStatusResponse,
} from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { schoolStatusClient } from 'api';

import { useSchoolID } from './schools';

export const useSchoolMISStatus = <T = GetMISStatusResponse>(
  options?: UseQueryOptions<GetMISStatusResponse, Error, T, string[]>,
) => {
  const schoolID = useSchoolID();

  return useQuery(
    ['school-mis-status'],
    () =>
      schoolStatusClient.getMISStatus({
        schoolName: `schools/${schoolID}`,
        product: Product.SPARX_READER,
      }).response,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
};

export const SCHOOL_ONBOARDING_STATUS_QUERY_KEY = 'school-onboarding-status';
export const useSchoolOnboardingStatus = <T = GetSchoolOnboardingStatusResponse>(
  options?: UseQueryOptions<GetSchoolOnboardingStatusResponse, Error, T, string[]>,
) => {
  const schoolID = useSchoolID();

  return useQuery(
    [SCHOOL_ONBOARDING_STATUS_QUERY_KEY],
    () =>
      schoolStatusClient.getSchoolOnboardingStatus({ schoolName: 'schools/' + schoolID }).response,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
};
