import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Tabs } from 'components/tabs/tabs';
import { Title } from 'components/title/title';
import { useSchoolInInterimState } from 'hooks/school-calendar';
import moment from 'moment';
import { useUser, useUserStaffHasOneOfStaffRole } from 'queries/session';
import { useMediaQuery } from 'react-responsive';
import { Navigate, useNavigate } from 'react-router-dom';
import { View } from 'views';
import { TeacherContent } from 'views/teacher/components/teacher-content';
import { HandInSummary } from 'views/teacher/home-view/hand-in-summary/hand-in-summary';
import { pathForView } from 'views/views';

import { useShowGettingStarted } from './getting-started-widget/hooks';
import styles from './home-view.module.css';
import { HomeViewDepartment } from './home-view-department';
import { HomeViewTeacher } from './home-view-teacher';
import { useShowPostReset } from './post-reset-widget/hooks';

export enum HomeViewTab {
  None = 'None',
  TeacherView = 'Teacher view',
  DepartmentView = 'Department view',
}

export const HomeView = ({ currentTab }: { currentTab: HomeViewTab }) => {
  const user = useUser();
  const navigate = useNavigate();
  const currentHour = moment().hour();
  const hourText = currentHour < 12 ? 'morning' : currentHour < 18 ? 'afternoon' : 'evening';
  const isInterim = useSchoolInInterimState();
  const { showPostReset } = useShowPostReset();
  const { showGettingStarted } = useShowGettingStarted();

  const isNarrowScreen = useMediaQuery({ query: '(max-width: 1150px)' });
  const widgetsOnOneLine = useMediaQuery({ query: '(min-width: 1440px)' });
  const trainingWidgetWidth =
    isNarrowScreen ||
    (widgetsOnOneLine && [isInterim, showPostReset, showGettingStarted].filter(a => a).length % 2)
      ? 'narrow'
      : 'wide';
  const showDepartmentTabDefault = useUserStaffHasOneOfStaffRole([
    StaffRole.SPARX_LEADER,
    StaffRole.HEAD_OF_DEPARTMENT,
    StaffRole.SENIOR_LEADER,
    StaffRole.SENIOR_LEADER_OTHER,
    StaffRole.TRUST_DIRECTOR,
  ]);
  if (currentTab === HomeViewTab.None) {
    if (showDepartmentTabDefault) {
      return <Navigate to={pathForView(View.DepartmentDashboard)} />;
    } else {
      return <Navigate to={pathForView(View.Dashboard)} />;
    }
  }

  return (
    <TeacherContent narrow={true}>
      <Title>Home</Title>
      <h2>
        Home{' '}
        <span className={styles.WelcomeMessage}>
          Good {hourText}, {user?.firstName}
        </span>
      </h2>

      <div className={styles.HomeTabs}>
        <Tabs
          analyticsEvent={{
            category: 'teacher home view',
            action: 'select tab',
            labels: {
              tabType: currentTab,
            },
          }}
          tabs={[
            {
              id: HomeViewTab.TeacherView,
              name: 'Teacher View',
              selected: location?.pathname === '/teacher/dashboard',
              onClick: () => navigate('/teacher/dashboard'),
            },
            {
              id: HomeViewTab.DepartmentView,
              name: 'Department View',
              selected: location?.pathname === '/teacher/dashboard/department',
              onClick: () => navigate('/teacher/dashboard/department'),
            },
          ]}
        />
      </div>

      {currentTab === HomeViewTab.TeacherView && (
        <HomeViewTeacher
          isInterim={isInterim}
          showPostReset={showPostReset}
          trainingWidgetWidth={trainingWidgetWidth}
          showGettingStarted={showGettingStarted}
        />
      )}
      {currentTab === HomeViewTab.DepartmentView && (
        <HomeViewDepartment
          isInterim={isInterim}
          showPostReset={showPostReset}
          showGettingStarted={showGettingStarted}
        />
      )}
      {!isInterim && <HandInSummary />}
    </TeacherContent>
  );
};
