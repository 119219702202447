import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { useSchoolInInterimState } from 'hooks/school-calendar';
import { useSchoolCalendar } from 'queries/school-calendar';
import { useSchool } from 'queries/schools';

import { Tab } from '../../types';
import { useMISTab } from '../mis-tab/hooks';
import { MISStatus } from '../mis-tab/types';

export const useResetTab = (): Tab & { hasReset: boolean } => {
  const misTab = useMISTab();
  const { isLoading: isSchoolLoading } = useSchool();
  const { isLoading: isSchoolCalendarLoading } = useSchoolCalendar();
  const isInterim = useSchoolInInterimState();

  const hasWarning = isInterim && misTab.status !== MISStatus.READY;
  const subtitle = !isInterim ? 'Site reset' : 'Ready for reset';
  const pageSubtitle = !isInterim ? 'Reset!' : 'Action required';

  const isLoading = isSchoolLoading || isSchoolCalendarLoading;

  return {
    id: 'reset',
    title: 'Reset Sparx Reader',
    subtitle,
    pageSubtitle,
    icon: faRedo,
    responsibility: 'Sparx Leader & Head of Department',
    hasWarning,
    hasSuccess: !isInterim,
    hasReset: !isInterim,
    isLoading,
  };
};
