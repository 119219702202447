import { faUsers } from '@fortawesome/free-solid-svg-icons';
import {
  IStudentWithExperience,
  useAssessmentPackages,
  useEnglishStudentGroups,
  useTeacherStudentsWithExperience,
} from 'queries/management';
import { useAllHomeworks } from 'queries/tasks';
import { useMemo } from 'react';

import { Tab } from '../../types';
import { useMISTab } from '../mis-tab/hooks';
import { MISStatus } from '../mis-tab/types';
import { useResetTab } from '../reset-tab/hooks';

type Group = {
  id: string;
  name: string;
  studentCount: number;
  loggedInStudentCount: number;
  readingTestStudentCount: number;
  hasHomeworkPlan: boolean;
};

export const useImportTab = (): Tab & { groups: Group[] } => {
  const resetTab = useResetTab();
  const misTab = useMISTab();
  const { data: englishGroups, isLoading: isLoadingStudentGroups } = useEnglishStudentGroups();
  const { data: students, isLoading: isLoadingStudents } = useTeacherStudentsWithExperience();
  const { data: homeworks, isLoading: isLoadingHomeworks } = useAllHomeworks();
  const {
    data: packages,
    isLoading: isLoadingPackages,
    isFetching: isFetchingPackages,
  } = useAssessmentPackages(Object.keys(students || {}), 'all', { refetchInterval: false });

  const groupIDStudents: Record<string, IStudentWithExperience[]> = useMemo(() => {
    const result: Record<string, IStudentWithExperience[]> = {};
    Object.values(students || {}).forEach(student => {
      if (!result[student.studentGroupId]) {
        result[student.studentGroupId] = [];
      }
      result[student.studentGroupId].push(student);
    });
    return result;
  }, [students]);

  const completedAssessmentStudents = useMemo(() => {
    const set = new Set<string>();
    if (!packages) return set;
    packages
      .filter(pkg => pkg.package && pkg.package.tasksComplete >= pkg.package.tasksTotal)
      .forEach(pkg => {
        if (pkg.package) set.add(pkg.package.studentId);
      });
    return set;
  }, [packages]);

  const groups: Group[] = useMemo(
    () =>
      englishGroups?.map(group => {
        const id = group.name.split('/')[3];
        const students = groupIDStudents[id];
        const loggedInStudentCount = students?.filter(student => student.lastLogin).length || 0;
        const readingTestStudentCount =
          students?.filter(student => completedAssessmentStudents.has(student.userId)).length || 0;
        const hasHomeworkPlan =
          homeworks?.find(homework => homework.studentGroupId === id) !== undefined;

        return {
          id,
          name: group.displayName,
          studentCount: students?.length || 0,
          loggedInStudentCount,
          readingTestStudentCount,
          hasHomeworkPlan,
        };
      }) || [],
    [completedAssessmentStudents, englishGroups, groupIDStudents, homeworks],
  );

  const allClassesHaveHomeworkPlan =
    groups.length > 0 && groups.filter(group => group.hasHomeworkPlan).length === groups.length;

  if (!resetTab.hasReset) {
    return {
      id: 'import-classes-and-students',
      title: 'Import Classes & Students',
      subtitle: 'Reset Sparx Reader first',
      pageSubtitle: '',
      icon: faUsers,
      isDisabled: true,
      groups: [],
    };
  }

  const subtitle =
    misTab.status !== MISStatus.READY && misTab.status !== MISStatus.RESET
      ? misTab.subtitle
      : allClassesHaveHomeworkPlan
        ? 'Imported & set up'
        : 'Action required';

  const pageSubtitle =
    misTab.status !== MISStatus.READY && misTab.status !== MISStatus.RESET
      ? misTab.pageSubtitle
      : allClassesHaveHomeworkPlan
        ? 'Imported & set up'
        : 'Action required';

  return {
    id: 'import-classes-and-students',
    title: 'Import Classes & Students',
    subtitle,
    pageSubtitle,
    icon: faUsers,
    responsibility: 'Sparx Leader & Head of Department',
    isLoading:
      misTab.isLoading ||
      isLoadingStudentGroups ||
      isLoadingStudents ||
      isLoadingHomeworks ||
      (isLoadingPackages && isFetchingPackages),
    hasWarning: misTab.hasWarning,
    hasSuccess: misTab.hasSuccess && allClassesHaveHomeworkPlan,
    groups,
  };
};
