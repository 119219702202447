import { ModuleSpec } from '@sparx/teacher-training';

import {
  moduleEssential,
  moduleTrinityLaunchingReader,
  moduleTrinitySuccessWithReader,
  moduleUsingSparxReader,
} from './module-specs';

export type TrainingTab = {
  id: string;
  title: string;
  modules: ModuleSpec[];
};

export const EssentialTrainingTab: TrainingTab = {
  id: 'essential-training',
  title: 'Essential Training',
  modules: [moduleEssential],
};
export const UsingSparxReaderTab: TrainingTab = {
  id: 'using-sparx-reader',
  title: 'Using Sparx Reader',
  modules: [moduleUsingSparxReader],
};
export const MaximisingImpactTab: TrainingTab = {
  id: 'maximising-impact',
  title: 'Maximising Impact',
  modules: [moduleTrinityLaunchingReader, moduleTrinitySuccessWithReader],
};

export const trainingTabs: TrainingTab[] = [
  EssentialTrainingTab,
  UsingSparxReaderTab,
  MaximisingImpactTab,
];
